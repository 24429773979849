@import './swatches';
$body-breakpoint: 800px;

body {
  font-family: 'Roboto', serif;
  font-weight: 300;
  max-width: $body-breakpoint;
  $body-padding: 10px;
  $body-padding-breakpoint: $body-breakpoint + ($body-padding * 4);
  padding: 0 $body-padding;
  background-color: $swatch05;

  @media all and (min-width: $body-padding-breakpoint) {
    margin: 0 auto;
    padding: 0;
  }
}

a {
  text-decoration: none;
  cursor: pointer;

  &:link,
  &:visited {
    color: $swatch01;
  }

  &:hover,
  &:active {
    color: $swatch02;
  }
}

.imageWrap {
  width: 175px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 20px;
  padding-bottom: 20px;

  .image {
    max-width: 100%;
    max-height: 100%;
  }

  @media all and (min-width: 450px) {
    float: left;
    padding-bottom: 5px;
  }
}

// ---------------------------
// -------- HEADER -----------
// ---------------------------
$header-large-breakpoint: 610px;

header {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  @media all and (min-width: $header-large-breakpoint) {
    flex-direction: row;
  }

  .title {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: -2px;
    line-height: 26px;

    a {
      &:link,
      &:visited {
        color: $swatch02;
      }
    }

    @media all and (min-width: $header-large-breakpoint) {
      flex: 2;
    }
  }

  .links {
    text-align: right;
    margin-top: 10px;

    @media all and (min-width: $header-large-breakpoint) {
      flex: 1;
      margin-top: 0;
    }
  }

  .link {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 300;
    text-transform: uppercase;
    padding: 0 4%;

    @media all and (min-width: $header-large-breakpoint) {
      padding: 0 16px;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

// -------------------------
// -------- HOME -----------
// -------------------------
main {
  margin-top: 40px;

  .post {
    min-height: 150px;
    border-bottom: 1px solid $swatch03;
    margin-bottom: 20px;
    padding-bottom: 15px;
    cursor: pointer;

    &:hover {
      .title {
        color: $swatch01;
      }
    }
  }

  a {
    &:last-child {
      .post {
        border-bottom: 0;
      }
    }
  }

  .title {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    line-height: 26px;
    font-weight: 500;
    text-transform: uppercase;
    color: $swatch02;
    transition: color 300ms ease;
  }

  .timestamp {
    margin-top: 10px;
    font-size: 14px;
    color: $swatch04;
  }

  .sample {
    font-size: 16px;
    line-height: 24px;
    color: $swatch02;
  }
}

// -------------------------
// -------- POST -----------
// -------------------------
section {
  &.post {
    margin-top: 40px;
    font-size: 16px;
    line-height: 24px;
    color: $swatch02;
    margin-bottom: 30px;

    h2,
    h3 {
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      color: $swatch02;
      margin-bottom: 0;
    }

    h2 {
      font-size: 26px;
      line-height: 28px;
      margin-top: 30px;
    }

    h3 {
      font-size: 22px;
      line-height: 24px;
      margin-top: 26px;
    }

    pre {
      font-size: 14px;

      &.prettyprint {
        padding: 5px;

        @media all and (min-width: $header-large-breakpoint) {
          padding: 15px;
        }
      }
    }

    img {
      max-width: $body-breakpoint;
    }

    .center {
      display: flex;
      justify-content: center;
    }
  }
}

// -------------------------------
// -------- POST TITLE -----------
// -------------------------------
.postTitle {
  margin-bottom: 20px;

  h1 {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    font-size: 30px;
    font-weight: 400;
    color: $swatch02;
    text-transform: uppercase;
    line-height: 32px;
  }

  .timestamp {
    margin-left: 1px;
    margin-top: 10px;
    font-size: 14px;
    color: $swatch04;
  }
}

// ---------------------------
// -------- FOOTER -----------
// ---------------------------
footer {
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid $swatch03;
  padding-top: 8px;
  margin-bottom: 20px;

  .content {
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    font-weight: 300;
    color: $swatch04;
  }

  .link {
    margin-left: 4px;
  }
}
